<template>
  <v-container fluid  fill-width>
    <v-row>
      <v-col
        align="center"
        style="cursor: default;"
      >
        <v-img
          max-height="100"
          max-width="200"
          src="@/assets/images/logo_lg.png"
          :class="'mb-6'"
        />
        <p class="mb-2">お探しのページは見つかりませんでした。</p>
        <p class="mb-9">URLをご確認のうえ、もう一度お試しください。</p>
        <base-button
          @click="toTop"
          :class-name="'py-6 font-weight-bold font-white'"
          :color="'primary'"
          :outset="false"
          :width="'180'"
          style="cursor: pointer;"
        >
          <template v-slot:text>ログイン画面へ</template>
        </base-button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseButton from '@/components/atoms/BaseButton';
export default {
  name: 'NotFound',
  components: {
    BaseButton
  },
  mixins: [AppStyleMixin],
  methods: {
    toTop :function() {
      this.$router.push({ path: '/login' });
    }
  }
};
</script>